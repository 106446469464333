//@import "old.less";
//@import "style.less";
//@import "color.less";

@sizes: {
  sm: 576px;
  md: 768px;
  lg: 992px;
  xl: 1200px;
}
.appearance(@appearance: none){
  -webkit-appearance: @appearance;
     -moz-appearance: @appearance;
          appearance: @appearance;
}
.box-shadow(@x: 0; @y: 0; @blur: 1px; @color: #000){
  -webkit-box-shadow: @arguments;
     -moz-box-shadow: @arguments;
          box-shadow: @arguments;
}
.box-shadow-rgba(@argu){
  -webkit-box-shadow: @argu;
     -moz-box-shadow: @argu;
          box-shadow: @argu;
}
.border-radius(@radius: 5px){
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}
.background-size(@attr){
  -webkit-background-size: @attr;
     -moz-background-size: @attr;
       -o-background-size: @attr;
          background-size: @attr;
}
// .at-top{
//     opacity:0.5;
//     img {
//         -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
//         filter: grayscale(100%);
//       }
// }

// .parallax {
//   perspective: 2px;
//   height: 100vh;
//   overflow-x: hidden;
//   overflow-y: auto;
// }

.pst-box-styl2.rev:before {
  content: "";
  background: #333;
  width: 200%;
  left: -50%;
  height: 120%;
  top: -10%;
  position: absolute;
  z-index: -1;
}
.rev .pst-inf-styl2.style2 {
  & > h4 {
  color: #833dcc;
    i {
      color: #444;
    }
  }
  & > p {
  color: #FFF;
  }
}
.customer{
  &-textarea {
    background-color:#424344;
    width: 100%;
    height: 150px;
    color: #a5a5a5;
    .box-shadow-rgba(5px 7px 0 rgba(0,1,1,.16));
    padding: 15px 30px;
    .border-radius();
  }
  &-head {
    font-size: 36px;
    color: #833dcc;
  }
  &-thankyou {
    height: 648px;
  }
  &-thankyou>div {
    text-align: center;
    margin-top: calc(50% - 64.5px);
  }
  &-thankyou.promotion>div {
    margin-top: calc(50% - 500px);
  }
}
.spinner-border.customer-contact-spinner {
  width: 8rem;
  height: 8rem;
}
.navbar{
  &-nav{
    @media (min-width: @sizes[md]) {
      margin-top: 1rem;
    }
    margin-right: 1rem;
  }
  &-brand,&-nav{
    margin-left: 1rem;
  }
  &-collapse {
    & > div {
      @media (min-width: @sizes[md]) {
        position: absolute;
      }
      top: 5px;
      right: 15px;
      font-size: 11px;
      & > span {
        margin-right: 0.5rem;
      }
    }
  }
}
.a-link:hover {
  color: #0056b3;
}
// .parallax__layer {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }
// .parallax__layer--base {
//   transform: translateZ(0);
// }
// .parallax__layer--back {
//   transform: translateZ(-1px);
// }
// .parallax-slider {
//   position: relative;
// }

.slider-wrapper {
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.slide {
  display: inline-block;
  width: 100%;
  height: 450px;
  flex-shrink: 0;
  transition: 0.9s ease-in-out transform;
  backface-visibility: hidden;
  .background-size(cover);
  .inner{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: white;
    text-align: center;
    .subtitle {
      // font-family: $font-family-secondary;
      font-size: 2.2rem;
      line-height: 1.2em;
      font-weight: 200;
      font-style: italic;
      letter-spacing: 3px;
      color: rgba(255,255,255,0.5);
      margin-bottom: 5px;
    }

    .title {
      // font-family: $font-family-primary;
      font-size: 3rem;
      line-height: 1.2em;
      text-transform: uppercase;
      letter-spacing: 3px;
      margin-bottom: 40px;
    }
    .btn{
      border: 1px solid #fff;
      padding: 14px 18px;
      text-transform: uppercase;
      // font-family: $font-family-primary;
      font-size: 0.8rem;
      letter-spacing: 3px;
      color: #fff;
      text-decoration: none;
      transition: all .2s ease;
      &:hover{
        background: #fff;
        color: #000;
      }
    }
  }
}
.drk-bg {
  .checkbox-wrapper {
    margin-bottom: 15px;
    &.inline {
      display: inline-block;
      margin-right: 30px;
    }
    .fa {
      color: #FFF;
      font-size: 21px;
      margin-right: 5px;
    }
    .checkbox-input {
      display: none;
    }
    label {
      color: #b9b9b9;
      cursor: pointer;
      vertical-align: middle;
    }
  }
  select.form-control {
    background: #424344;
    background-color: #424344;
    background-image: none;
    border: 0px;
    float: left;
    width: 100%;
    font-size: 15px;
    color: #a5a5a5;
    height: 50px;
    padding: 0 30px;
    margin-bottom: 20px;
    line-height: 50px;
    .border-radius();
    .box-shadow-rgba(5px 7px 0 rgba(0,1,1,.16));
    .appearance(none);
  }
  .select-wrapper{
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .select-wrapper:before{
    right: 15px;
    color: #FFF;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    top: calc(50% - .7em);
  }
}

.pst-inf-styl2 > h4 + p {
  margin-bottom: 15px;
}

.header-contact {
  margin-right:  8px;
  a {
    font-size: 13px;
  }
}

.get-pointer {
  cursor: pointer;
}
// .carousel .item {
//   -webkit-transition: opacity 1s;
//    -moz-transition: opacity 1s;
//    -ms-transition: opacity 1s;
//    -o-transition: opacity 1s;
//    transition: opacity 1s;
//    width: 100%; height: 700px;
//    -webkit-background-size: cover;
//    -moz-background-size: cover;
//    -o-background-size: cover;
//    background-size: cover;

// }
// .carousel .one {
//    background: url(http://placehold.it/1920X800) no-repeat center center fixed;

// }
// .carousel .two {
//    background: url(http://placehold.it/1920X800) no-repeat center center fixed;

// }
// .carousel .three {
//    background: url(http://placehold.it/1920X800) no-repeat center center fixed;

// }

.carousel .active {
  opacity: 1 !important;
}

.carousel .active.left {
  left:0;
  opacity:0;
  z-index:2;
}

.price {
  padding: 8px 40px;
  background: #6610f2;
  color: #FFF;
  display: inline-block;
  margin-bottom: 15px;
}
.thm-btn {
  margin-right: 15px;
  font-size: 15px;
}

.discount {
  del {
    color: rgba(darkgray, 0.5);
    text-decoration: none;
    position: relative;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(darkgray, 0.8);
      height: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-7deg);
    }
  }
  ins {
    font-size: 32px;
    text-decoration: none;
    padding: 1em 1em 1em .5em;
  }
}

